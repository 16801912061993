body.dark {
    background: #f1f5f9;
}

.dark .sidebar {
    background-color: rgb(17, 24, 39);
    color: rgb(255, 255, 255);

    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, .2), 0px 5px 8px 0px rgba(0, 0, 0, .14), 0px 1px 14px 0px rgba(0, 0, 0, .12);
}

.dark .sidebar ul li.has-dropdown span, .dark .sidebar ul li a {
    color: rgba(255, 255, 255, 0.7);
}

.dark .sidebar ul li.has-dropdown:hover span,
.dark .sidebar ul li.has-dropdown.active span,
.dark .sidebar .scrollbar-container > ul > li:not(.has-dropdown, .head-line):hover,
.dark .sidebar .scrollbar-container > ul > li:not(.has-dropdown).active {
    color: rgb(255, 255, 255);
    transition: border-radius 0.15s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    background: rgba(255, 255, 255, 0.1) !important;
}

.dark .sidebar ul li.head-line {
    color: #1188ed;
}

.dark .sidebar .scrollbar-container > ul > li:not(.has-dropdown).active:after {
    background-color: #1188ed;
}

.dark .sidebar .text-muted {
    color: rgb(148, 163, 184) !important;
    font-weight: 500 !important;
}

.dark .wrapper .header {
    background-color: #fff;
    color: #000;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -1px rgba(0, 0, 0, .06);
}

.dark .header .text-white {
    color: #000 !important;
}

.dark .header .menu-bars {
    color: rgba(0, 0, 0, 0.54);
}


.dark .box {
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .06);

}

.dark .box:not(.box-table) {
    border-radius: 1rem !important;
}

.dark .box.box-table ~ .table-responsive {
    margin-top: -3px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .06);
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
}
