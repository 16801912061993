/*@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Thai+Looped:wght@100;200;300;400;500;600;700;800;900&family=Noto+Sans+Thai:wght@100;200;300;400;500;600;700;800;900&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
/*@import url('https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');*/

body {
    margin: 0;
    /*font-family: sans-serif !important;*/
    font-family: 'Noto Sans Thai', 'Roboto', sans-serif;
    /*font-family: 'Roboto', sans-serif;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    /*background: #f5f5f5 !important;*/
    background: rgb(84, 208, 172);
    background: linear-gradient(180deg, rgba(84, 208, 172, 1) 0%, rgba(80, 159, 146, 1) 55%, rgba(78, 107, 116, 1) 100%);
    background-attachment: fixed;
    overflow-x: hidden;
}

input {
    font-family: 'Noto Sans Thai', 'Roboto', sans-serif !important;
}

a:hover,
a {
    color: #0f171e;
    text-decoration: none;
}

#root {
    position: relative;
}

.rnc__base {
    top: 0;
    left: 0;
}

img {
    max-width: 100%;
    height: auto;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.form-control-plaintext:focus-visible {
    outline: none;
    box-shadow: none;
}

.text-primary {
    color: #0a63af !important;
}

.bg-primary {
    background-color: #0a63af !important;
}


.wrapper {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: stretch;
    position: relative;
    z-index: 7;
    transition: all .5s;
}

.wrapper .header {
    /*display: flex;*/
    /*justify-content: space-between;*/
    padding: 8px 30px;
    background-color: #0a63af;
    /*position: fixed;*/
}


.wrapper .header .input-group-prepend {
    margin-right: 0 !important;

}

.header .profile {
    display: flex;
    align-items: center;
    line-height: 1.2;
}

.header .profile a:hover {
    text-decoration: none;
}

.header .profile .btn {
    background-color: transparent !important;
    border: none !important;
    padding: 0;
}

.header .profile .btn.dropdown-toggle::after {
    display: none;
}

.header .dropdown-item {
    padding: 0.5rem 1.5rem;
}

.header .dropdown-menu {
    box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
    border: none;
    border-radius: 8px;
    transition: all .3s;
}

.header .menu-bars {
    font-size: 22px;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    /*display: none;*/
    opacity: 1;
    transition: all 1s;
}

.sidemenu-active .header .menu-bars {
    display: none;
    opacity: 0;
}


.header .shortcut {
    font-size: 22px;
    color: rgba(0, 0, 0, 0.54);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1px;
    transition: all .3s;
}

.header .shortcut:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.header .shortcut:not(.menu-bars) {
    display: none;
}

@media (min-width: 992px) {
    .header .shortcut:not(.menu-bars) {
        display: flex;
    }
}

.wrapper-content {
    flex: 1 1 auto;
    flex-grow: 1;
    transition: all .5s;
    /*padding: 0 30px;*/
}

@media (min-width: 1200px) {
    .wrapper.sidemenu-active .wrapper-content {
        margin-left: 200px;
    }
}

@media (min-width: 1599px) {
    .wrapper.sidemenu-active .wrapper-content {
        margin-left: 280px;
    }
}


.wrapper-content .title * {
    color: #0a63af !important;
}

@media (max-width: 1199px) {
    .wrapper.sidemenu-active {
        position: relative;
    }

    .wrapper.sidemenu-active:after {
        content: '';
        background-color: rgba(0, 0, 0, 0.5);
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    .header .profile,
    .header .logout .btn {
        font-size: 13px;
    }

    .header .profile img {
        width: 40px;
    }
}

.wrapper-manage {
    padding: 15px;
}

.wrapper-content .form-control {
    height: 38px;
    border: 1px solid #e7e2e2;
    background-color: #fff;
}

.form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef !important;
    cursor: not-allowed;
}

.input-group-text {
    background-color: #f1f1f1 !important;
    border: 1px solid #e7e2e2;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.MuiInputBase-sizeSmall {
    padding: 5px !important;
}

.input-group-text svg {
    color: #0a63af;
    font-size: 26px;
}

.form-control:focus,
.form-control:visited {
    outline: none;
    box-shadow: none;
}

form .form-control {
    background-color: #fff;
    border: 1px solid #dfe1e5;
}

.form-control {
    background-color: rgba(250, 250, 250, 0.38) !important;
}

.wrapper-manage .input-group-text svg {
    color: rgba(0, 0, 0, 0.26);
    font-size: 20px;
}

.wrapper-manage .input-group-text,
.wrapper-manage .input-group .form-control {
    /*background-color: #fff !important;*/
}

.wrapper-manage .btn {
    border-radius: 5px;
    height: auto;
}

.btn-primary,
.wrapper-manage .btn.btn-primary:hover,
.wrapper-manage .btn.btn-primary {
    border-color: #0a63af;
    background: #0a63af !important;
    background-color: #0a63af !important;
}

.btn-primary.disabled, .btn-primary:disabled {
    border-color: transparent !important;
}

.sidebar {
    position: fixed;
    left: 0;
    top: 0;
    transform: translateX(-100%);
    z-index: 9;
    flex: 0 0 auto;
    width: 280px;
    min-height: 100vh;
    background-color: #0a63af;
    transition: all 0.3s;
    -webkit-box-shadow: 2px 0px 5px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 2px 0px 5px 0px rgba(0, 0, 0, 0.05);
}

.sidebar.active {
    position: relative;
    transform: translateX(0);
}

.sidebar .mobile-close-memu {
    display: none;
}

@media (min-width: 1200px) {
    .sidebar {
        position: fixed !important;
    }

    .scrollbar-container {
        height: calc(100vh - 61px);
    }
}

@media (max-width: 1599px) {
    .sidebar {
        width: 200px;
    }
}

@media (max-width: 1199px) {
    .sidebar {
        position: fixed;
        width: 250px;
        left: 0;
        top: 0;
        transform: translateX(-100%);
        height: 100%;
    }

    .sidebar.active {
        position: fixed;
        transform: translateX(0);

    }

    .sidebar > ul {
        overflow-y: scroll;
        height: calc(100vh - 130px);
    }

    .wrapper .sidebar .mobile-close-memu {
        display: none;
    }

    .wrapper.sidemenu-active .sidebar .mobile-close-memu {
        cursor: pointer;
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        transform: translateX(100%);
        color: #fff;
        font-size: 32px;
        background-color: #0a63af;
        padding: 0px 10px;
    }
}


.sidebar .company-name {
    font-size: 13px;
    font-weight: 500;
}

.sidebar .logo {
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    margin: 5px 0;
}

.sidebar .menu-bars {
    cursor: pointer;
}

.sidebar .menu-bars svg {
    font-size: 22px;
}

.sidebar .logo img {
    width: 170px;
}

.sidebar ul {
    margin: 0;
    padding: 0 20px;
    list-style: none;
}

.sidebar ul li {
    display: block;
    border-radius: 5px;

}

li.has-dropdown span {
    position: relative;
}

li.has-dropdown span .dropdown-arrow {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}


.sidebar ul li:not(:last-child) {
    /*margin-bottom: 8px;*/
    margin-bottom: 3px;
}

.sidebar ul li svg {
    font-size: 22px;
    margin-right: 10px;
    color: #c1c1c1;
}

.sidebar ul li.has-dropdown span,
.sidebar ul li a {
    padding: 8px 10px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #c1c1c1;
}

.sidebar ul li.has-dropdown span {
    padding: 8px 10px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #c1c1c1;
    border-radius: 5px;
    cursor: pointer;
}

.sidebar ul li.has-dropdown > ul {
    display: none;
}

.sidebar ul li.has-dropdown.active ul {
    display: block;
    margin-left: -13px;
    padding-right: 0 !important;
}

.sidebar ul li.has-dropdown > ul li a {
    padding: 5px 10px 0 10px;
}


.sidebar ul li.has-dropdown:hover span,
.sidebar ul li.has-dropdown.active span,
.sidebar .scrollbar-container > ul > li:not(.has-dropdown, .head-line):hover,
.sidebar .scrollbar-container > ul > li:not(.has-dropdown).active {
    /*background-color: #0a63af;*/
    background: rgb(84, 206, 177);
    background: linear-gradient(90100deg, rgba(84, 206, 177, 1) 0%, rgba(88, 201, 196, 1) 55%, rgba(92, 159, 190, 1) 100%);
}

.sidebar .scrollbar-container > ul > li:not(.has-dropdown).active {
    position: relative;
    overflow: hidden;
}

.sidebar .scrollbar-container > ul > li:not(.has-dropdown).active:after {
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    width: 4px;
    background-color: #67fdcd;
    /*border-top-right-radius: 5px;*/
    /*border-bottom-right-radius: 5px;*/
}


.sidebar ul li.has-dropdown:hover > span > svg,
.sidebar ul li.has-dropdown.active > span > svg,
.sidebar ul li.active > span,
.sidebar ul li:not(.has-dropdown):hover spn > svg,
.sidebar ul li:hover > *,
.sidebar ul li a:hover,
.sidebar ul li a:hover svg,
.sidebar ul li:not(.has-dropdown).active svg,
.sidebar .scrollbar-container > ul > li.active > a {
    color: #fff !important;
    text-decoration: none;
}

.sidebar ul li.has-dropdown.active ul li:hover {
    background-color: transparent !important;

}

.sidebar ul li.has-dropdown.active ul li svg {
    margin-right: 3px;
}

.sidebar ul li.has-dropdown.active ul li.active a,
.sidebar ul li.has-dropdown.active ul li.active svg,
.sidebar ul li.has-dropdown.active ul li:hover svg,
.sidebar ul li.has-dropdown.active ul li:hover a {
    color: #fff !important;
}

.sidebar ul li.has-dropdown.active ul li.active a {
    font-weight: 600;
}

.sidebar ul li.logout:hover,
.sidebar ul li.head-line:hover {
    background-color: transparent !important;
}

.sidebar ul li.head-line {
    font-size: 13px;
    font-weight: 600;
    color: #ccc;
}

.table thead th {
    background-color: #111827FF;
    color: #fff;
}

.table th {
    font-size: 14px;
    color: #333;
    font-weight: 500;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #efefef;
}

.table-striped tbody tr:nth-of-type(even) {
    background-color: #fff;
}

.table td .h5,
.table td h6 {
    font-size: 14px !important;
}

.table thead th {
    border-bottom: none;
}

.t-icon > *,
a.t-icon,
a.printer {
    font-size: 22px;
    color: #3b3b3b;
    cursor: pointer;
}

.row-sm {
    margin-right: -5px;
    margin-left: -5px;
}

.row-sm > [class*="col-"] {
    padding-left: 5px;
    padding-right: 5px;
}

label {
    font-family: 'Noto Sans Thai', 'Roboto', sans-serif !important;
    font-weight: 600;
    color: #201f1f;
    font-size: 15px;
}

.form-group label span {
    color: #908f8f;
    font-size: 12px;
}

.suggestion {
    position: relative;
    z-index: 9999999999999;
}

.suggestion input {
    position: relative;
    z-index: 999;
}

.suggestion-wrapper {
    position: absolute;
    top: 36px;
    left: 50%;
    transform: translateX(-50%);
    width: 95%;
    z-index: 888;
}

.suggestion-wrapper ul {
    margin: 0;
    padding: 0;
    list-style: none;
    box-shadow: 2px 0px 5px 0px rgb(0 0 0 / 5%);
    background-color: #fff;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    overflow: hidden;
}

.suggestion-wrapper ul li {

}

.suggestion-wrapper ul li a {
    padding: 5px 15px;
    display: block;
    cursor: pointer;
}

.suggestion-wrapper ul li a:hover {
    background-color: #0a63af;
    color: #fff !important;
}

.suggestion > *,
.suggestion .wrapper > *,
.suggestion .wrapper {
    min-height: 38px;
    height: 38px !important;
    box-shadow: none !important;
}


.suggestion .wrapper > div:nth-child(2) {
    background-color: #fff !important;
    z-index: 1000;
    /*position: absolute;*/
}

.suggestion .wrapper > .bWZouD {

    border: 1px solid #dfe1e5;
    /*transform: translateY(-20px);*/
    /*background-color: #000000 !important;*/
    /*position: relative !important;*/
    /*z-index: 1000 !important;*/
}

.suggestion .wrapper > .bWZouD > div.line {
    border: none !important;
}

.suggestion .bWZouD > ul > li {
    padding: 2px 0 2px 0;
}

.suggestion .bWZouD {
    height: auto !important;
    /*padding-top: 30px;*/
}

.suggestion .ViWoI {
    margin: -4px 14px 0 0 !important;
}

.suggestion .sc-bxivhb {
    position: relative;
    z-index: 88888888;
}

.paginate ul,
.paginate {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
}

@media (max-width: 767px) {
    .paginate {
        justify-content: center !important;
    }

    .paginate ul {
        margin-top: 15px !important;
        justify-content: center !important;
    }

    .paginate ul li {
        margin-bottom: 5px;
    }
}

.paginate .total-items {
    font-size: 14px;
    color: #000;
}

.paginate ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.paginate ul li > * {
    display: block;
    padding: 8px 15px;
    background-color: #fff;
    margin: 0 3px;
    border-radius: 3px;
    font-size: 16px;
}

.paginate ul li.disabled * {
    color: #8b8b8b;
}

.paginate ul li a {
    color: #000;
}

.paginate ul li a:hover {
    text-decoration: none;

}

.paginate ul li:not(.disabled):hover > *,
.paginate ul li.selected > * {
    background-color: #0a63af;
    color: #fff !important;
}


@media (max-width: 991px) {
    .table thead {
        display: none;
    }

    .table tfoot tr,
    .table tbody tr {
        display: block;
    }

    .table tfoot td,
    .table tbody td {
        display: block;
        text-align: left !important;
        padding: 5px 0.75rem;
    }

    .table td:first-child {
        padding: 10px 0.75rem 3px 0.75rem;
    }

    .table td:last-child {
        padding: 3px 0.75rem 10px 0.75rem;
    }

    .table tr td:not(:last-child) {
        border-bottom: 1px solid rgba(222, 226, 230, 0);
    }

    .table tr td:not(:first-child) {
        border-top: 1px solid rgba(222, 226, 230, 0.5);
    }

    .table td:before {
        content: attr(data-label);
        display: block;
        text-transform: uppercase;
        font-weight: bold;
    }

    .table td .img-card-s1 {
        width: 100px;
        /*max-height: 150px;*/
        padding-top: 30%;
        margin: 0;
        background-color: #1d5d90;
        color: #fff;
        font-size: 22px;
    }

    .table td:before {
        content: attr(headers);
        text-align: left;
        width: 25%;
        display: inline-block;
        vertical-align: top;
    }

    .table td > div:not(.d-none) {
        display: inline-block !important;
        width: 70%;
    }

    .img-card-placeholder {
        background-color: #1d5d90;
        color: #fff;
    }

    .table tbody tr th {
        display: none;
    }
}


.login-section {
    padding: 7em 0;
}

.login-box .wrap {
    -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .06);
    -moz-box-shadow: 0 10px 34px -15px rgba(0, 0, 0, .24);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .06);
    background: #fff;
    border-radius: 15px;
    overflow: hidden;
}

.login-section .wrap {
    width: 100%;
    /*border-radius: 5px;*/


}

.login-wrap {
    position: relative;

}

.text-wrap, .login-wrap {
    width: 50%;
}

.text-wrap {
    background: #0a63af;
    /*background-image: url("assets/images/login-bg.svg");*/
    /*background-size: cover;*/
    color: #fff;
}

.btn {
    cursor: pointer;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border-radius: 30px;
    /*border-radius: 0;*/
    /*font-size: 15px;*/

    /*border-radius: 50px;*/
    /*height: 48px;*/
}

.login-page {
    padding: 10px 20px;
}


.modal-header .btn-close {
    display: none;
}

.modal-header .modal-title {
    font-size: 18px;
}

.box {
    padding: 15px;
    background-color: #fff;
    margin-bottom: 15px;
    /*border: 1px solid #e7eaec;*/
    /*border-radius: 5px;*/
    /*box-shadow: 0 1px 2px rgb(0 0 0 / 5%);*/
}

.box-table {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: -1px;
}

.box-header {
    font-size: 20px;
    padding-bottom: 10px;
    font-weight: 800;
    border-bottom: 1px solid #efeeee;
    margin-bottom: 15px;
    color: #111827FF
}

.box-header.lg {
    font-size: 22px;
    margin-bottom: 25px;
    padding-bottom: 5px;
}

.box-header.lg svg {
    font-size: 32px;
}

.box-header.lg .add-new svg {
    font-size: inherit;
}

.box-header .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.hide-title legend,
.hide-title label {
    display: none;
}

.hide-title > div {
    padding: 5px 15px 7px 15px !important;
}

.hide-title input {
    margin-top: -2px;
    height: inherit;
    padding: 0 !important;
}

.hide-title button {
    margin-top: -2px;
}

.box-image-upload {
    width: 100%;
    /*padding: 173px 0 0 !important;*/
    display: flex;
    align-items: center;
    justify-content: center;
    /*height: 200px;*/
    overflow: hidden;
    position: relative;
    border: 1px dashed #ccc;
    background-size: cover;
    background-position: center center;
    padding-top: 100%;
    background-color: #f6f6f6;
}

.box-image-upload:after {
    content: 'Select Image';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    text-align: center;
    transform: translate(-50%, -50%);
}

.box-image-upload.has-image:after {
    display: none;
}

.box-image-upload.has-image {
    background-color: #bebebe;
}

.box-image-upload img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 100%;
    max-width: 100% !important;
}

.box-image-upload img.imgNew {
    z-index: 2;
}

.box-image-upload img.imgOld {
    z-index: 1;
}

.box-image-upload input {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 999;
}


.img-card-placeholder {
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 100%;
    display: block;
    position: relative;
    overflow: hidden;
}

.img-circle {
    border-radius: 50%;
    background-color: #0a63af;
    color: #fff;
}

.img-rounded {
    border-radius: 8px;
    background-color: #0a63af;
    color: #fff;
}

.img-card-s1 {
    padding-top: 100%;
}

.img-card-s2 {
    padding-top: 60%;
}

.img-card-placeholder .image {
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: all 1s;
    display: flex;
    align-items: center;
    justify-content: center;
}


.autocomplete-search fieldset {
    /*top: -4px !important;*/
    /*height: 38px;*/
    border-color: #e7e2e2 !important;
}

.autocomplete-search .MuiInputBase-sizeSmall {
    padding-right: 65px !important;
}


.form-control-plaintext {
    font-size: 14px;
}

.table-custom tfoot th {
    background-color: #f5f5f5;
    padding: 8px;
}

.pointer {
    cursor: pointer;
}

.modal-body .modal-footer {
    margin: 30px -15px -15px -15px;
}

.table-scrollable {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    /*border: 1px solid #e7ecf1;*/
    /*margin: 10px 0 !important;*/
}

.table-scrollable > .table {
    width: 100% !important;
    margin: 0 !important;
    margin-bottom: 0;
    background-color: #fff;

}

.table-scrollable:not(.table-scrollable-lg) > .table {
    font-size: 12px;
}

.table-scrollable > .table th,
.table-scrollable > .table td {
    vertical-align: middle;
}

.table-scrollable:not(.table-scrollable-lg) > .table th,
.table-scrollable:not(.table-scrollable-lg) > .table td {
    font-size: 13px;
    font-family: "Helvetica Neue", helvetica, arial, sans-serif;
}

.progress-loading {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999988;
}

.progress-loading:after {
    content: '';
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9998;
}

.progress-inner {
    z-index: 9999999999;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 15px;
    border-radius: 5px;
}

.react-datepicker__navigation-icon::before {
    top: 12px !important;
}

.tab-tracking ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.tab-tracking li {
    /*overflow-y: auto;*/
    /*margin: 10px 0;*/
    box-sizing: border-box;
    display: flex;
    align-items: stretch;
    padding-bottom: 15px;
}

.tab-tracking li > div {
    display: flex;
    align-items: center;
}


.tab-tracking li .date {
    width: 115px;
    flex: 0 0 auto;
    text-align: center;
}

.tracking-lg .tab-tracking li .date {
    /*width: 250px;*/
}

.tab-tracking li .icon {

    padding: 0 10px;
    position: relative;
    width: 50px;
    flex: 0 0 auto;
}

.tab-tracking li .icon:after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background-color: #ccc;
    border-radius: 50%;
    margin: 0 auto;

}

.tab-tracking li .icon:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    background-color: #ccc;
    top: 0;
    left: 50%;
    transform: translate(-50%);

}

.tab-tracking li .icon:before {
    top: 50%;
    transform: translateY(-50%);
    height: 200%;
}

.tab-tracking ul li:first-child .icon:before {
    top: 50%;
    height: 100%;
    transform: none;
}

.tab-tracking ul li:last-child .icon:before {
    /*display: none;*/
    top: 0;
    height: 50%;
    transform: none;
}

.tab-tracking li .desc {
    display: block;
    flex: 1 1 auto;
    flex-direction: row;
    margin-left: 10px;
}

.tab-tracking ul li:last-child:first-child .icon:before {
    display: none !important;
}

.order-icon svg {
    font-size: 32px;
}

.ifrm {
    height: 100vh;
}

.table-head-left th,
.table-head-left td {
    font-size: 14px;
}

.table-head-left th {
    background-color: #efefef;
    font-weight: 500;
}

@media (max-width: 991px) {
    .form-filter {
        flex-wrap: wrap;
    }

    .form-filter .form-group {
        margin-right: 0 !important;
        width: 100% !important;
    }

    .form-filter .btn {
        width: 100%;
    }

    .MuiTabs-scroller button {
        max-width: 33.33%;
        font-size: 12px;
    }

    /*.MuiTabs-scroller > div {*/
    /*    flex-wrap: wrap;*/
    /*}*/
    .wrapper-manage,
    .box {
        padding: 15px;
    }

    .wrapper-manage .title > * {
        font-size: 20px;
    }

    .sidebar ul li svg {
        font-size: 20px;
        margin-right: 5px;

    }

    .sidebar ul li.has-dropdown span, .sidebar ul li a {
        font-size: 13px;
        align-items: start;
        padding: 8px 0;
    }

    .sidebar ul li.has-dropdown:hover span, .sidebar ul li.has-dropdown.active span, .sidebar ul li:not(.has-dropdown):hover, .sidebar .scrollbar-container > ul > li:not(.has-dropdown).active {
        background-color: transparent;
    }

    .sidebar ul li.has-dropdown:hover > span > svg, .sidebar ul li.has-dropdown.active > span > svg, .sidebar ul li.active > span, .sidebar ul li:not(.has-dropdown):hover spn > svg, .sidebar ul li:hover > *, .sidebar ul li a:hover, .sidebar ul li:not(.has-dropdown).active svg, .sidebar .scrollbar-container > ul > li.active > a {
        color: #fff !important;
        font-weight: 600;
        padding-left: 5px;
    }
}


.input-group-text:not(.right) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    /*margin-right: -1px;*/
}

.input-group-text.right {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: -1px;
}

.order-image-list {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.order-image-list .item {
    width: 40px;
}

.order-image-list .item .img-circle {
    border: 2px solid #e3e0e0;
    padding-top: 87%;
}

.order-image-list .item:not(:first-child) {
    margin-left: -20px;
}

@media (max-width: 991px) {
    .order-image-list {
        display: none;
    }
}

.custom-input-file {
    position: relative;
    padding: 5px;
    border: 1px solid #e7e2e2;
    border-radius: 3px;
    width: 100%;
}

.custom-input-file::-webkit-file-upload-button {
    visibility: hidden;
}

.custom-input-file::before {
    content: 'Select files';
    display: inline-block;
    background: linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 3px 8px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
    /*width: 100%;*/
}

.custom-input-file:hover::before {
    border-color: black;
}

.custom-input-file:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.react-datepicker__time-container {
    position: absolute;
    right: 0;
    top: -1px;
    transform: translateX(98%);
    border: 1px solid #aeaeae;
    /*height: 98%;*/
}

.react-datepicker__navigation {
    position: absolute;
    top: 2px;
}

.react-datepicker__navigation.react-datepicker__navigation--next {
    right: 0;
}

.edit-inline:hover,
.edit-inline {
    cursor: pointer;
    text-decoration: none;
    color: #000;
    /*font-weight: 600;*/
    border-bottom: 1px dashed #3275b2;
}

.table-button {
    align-items: center;
    justify-content: center;

}

.table-button .btn {
    border-radius: 2px;
    font-size: 16px;
    padding-top: 0px;
    padding-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.dashboard-card {
    box-shadow: 0 4px 12px #4e54a31a;
    border-radius: 5px;
    background: #fff;
    border: 1px solid #ece9f1;
    margin-bottom: 30px;
}

.dashboard-card-body {
    padding: 15px;
}

.dashboard-card-body .amount {
    display: flex;
    align-items: center;
    margin: 5px 0;
}

.dashboard-card-body .amount .icon {
    flex: 0 0 auto;
    width: 50px;
    margin-right: 10px;
}

.dashboard-card-body .amount .icon svg {
    font-size: 50px;
}

.dashboard-card-body .amount .desc {
    font-size: 22px;
    font-weight: 600;
}


.MuiInputLabel-formControl:not(.MuiFormLabel-filled, .Mui-focused) {
    transform: translate(14px, 16px) scale(1) !important;
}

.input-group > .custom-select:not(:first-child), .input-group > .form-control:not(:first-child) {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    border-left: none !important;
}

.input-group > .input-group-prepend > .input-group-text {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    background-color: #fff !important;
    border-right: none !important;
}

.MuiInputLabel-formControl {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

fieldset.MuiOutlinedInput-notchedOutline {
    border-color: #e7e2e2;
}

.has-error fieldset.MuiOutlinedInput-notchedOutline {
    border-color: #ff2f2f;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.46) !important;
}

.rdw-editor-toolbar {
    margin-bottom: -1px !important;
    border: 1px solid #e7e2e2 !important;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
}

.rdw-editor-main {
    border: 1px solid #e7e2e2;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 10px;
    min-height: 300px;
}

.editor-content {
    min-height: 250px;
    max-height: 500px;
}

.editor {
    position: relative;
}

.editor .delete {
    position: absolute;
    top: 7px;
    right: 7px;
    z-index: 99;
}

.editor .delete .btn {
    border-radius: 3px;
    padding: 2px 5px;
}

.rug .rug-handle:before {
    border: 1px solid #e3e3e3 !important;
}

.rug-items > div {
    z-index: 9999;
}

.rug-dragging-item {
    z-index: 9999;
}

.rug-card-upload-button {
    display: none;
}

.rug .rug-items.__card {
    min-height: auto !important;
}

.rug .rug-handle:before {
    width: 100% !important;
}

.drag-able {
    width: 100%;
    border: 1px dashed #353535;
    padding: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.drag-able .img {
    width: 50px;
    margin-right: 10px;
}


@media print {
    #root > .wrapper {
        display: none;
    }

    .modal-dialog {
        max-width: 100% !important;
    }

    .no-print {
        display: none;
    }

    .badge-success {
        background-color: #1c7430 !important;
        border: 1px solid #1c7430 !important;
        color: #1c7430 !important
    }

    .badge-danger {
        background-color: #f40909 !important;
        border: 1px solid #f40909 !important;
        color: #f40909 !important
    }

    .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
        float: left !important;
    }

    .col-md-12 {
        width: 100%;
    }

    .col-md-11 {
        width: 91.66666667%;
    }

    .col-md-10 {
        width: 83.33333333%;
    }

    .col-md-9 {
        width: 75%;
    }

    .col-md-8 {
        width: 66.66666667%;
    }

    .col-md-7 {
        width: 58.33333333%;
    }

    .col-md-6 {
        width: 50%;
    }

    .col-md-5 {
        width: 41.66666667%;
    }

    .col-md-4 {
        width: 33.33333333%;
    }

    .col-md-3 {
        width: 25%;
    }

    .col-md-2 {
        width: 16.66666667%;
    }

    .col-md-1 {
        width: 8.33333333%;
    }

}
